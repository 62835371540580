<template>
  <div class="text-center">
    <img class="w-75" src="@/assets/img/Components/Empty/empty.svg" alt="" />
    <h3 class="mt-5">We couldn't find anything.</h3>
    <p>This part seems to be empty.</p>
  </div>
</template>

<script>
export default {
  name: "Empty",
};
</script>

<style>
</style>