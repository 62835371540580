var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg view-overlay fade-up-ani pb-5",attrs:{"id":"company"}},[_c('div',{staticClass:"bg-white pt-9 pb-4 text-center"},[_c('Avatar',{attrs:{"member":_vm.org.member,"size":"large","img":_vm.org.logo,"link":'/company/' + _vm.org.id}}),_c('h3',{staticClass:"fade-up-ani pt-2",attrs:{"id":"company-name"}},[_vm._v(_vm._s(_vm.org.name))]),(_vm.org.categories)?_c('p',{staticClass:"fade-up-ani pt-1 pb-2",attrs:{"id":"company-desc"}},[_vm._v(" "+_vm._s(_vm.org.categories[0])+" ")]):_vm._e(),(!_vm.org.categories)?_c('p',{staticClass:"fade-up-ani pt-1 pb-2",attrs:{"id":"company-desc"}},[_vm._v(" - ")]):_vm._e(),_c('Tabs',{staticClass:"fade-up-ani",attrs:{"id":"company-tabs","tabs":_vm.tabs,"activeTab":_vm.activeTab,"updateCB":_vm.switchTab,"pinIndex":2}})],1),_c('div',{staticClass:"fade-up-ani pt-5",attrs:{"id":"company-content-box"}},[(_vm.activeTab === 'Overview')?_c('div',[(_vm.rankings.length > 0)?_c('div',{staticClass:"card-bg mx-4 rounded pt-4 pb-5"},[_c('h3',{staticClass:"ml-6 mt-4 mb-6"},[_vm._v("Rankings")]),_vm._l((_vm.rankings),function(ranking,index){return _c('Cell',{key:index,staticClass:"mx-4 mt-3",attrs:{"link":'/ranking/' +
            ranking.id +
            '?eventId=' +
            _vm.$event.id +
            '&event=' +
            _vm.$event.eid,"title":ranking.name,"desc":ranking.desc,"value":ranking.value,"unit":ranking.unit,"descMax":24,"index":index}})}),(_vm.$route.query.event)?_c('div',{staticClass:"mx-4"},[_c('router-link',{staticClass:"btn btn-block mt-4 btn-black",attrs:{"to":'/' + _vm.$route.query.event + '/rankings',"tag":"button"}},[_vm._v("View Rankings")])],1):_vm._e()],2):_vm._e(),(_vm.org.thesis_link)?_c('Mat',{staticClass:"mb-5 mt-5",attrs:{"title":_vm.overviewMat.title,"desc":_vm.overviewMat.desc,"avatar":_vm.overviewMat.avatar,"CTA":_vm.openInternWebsite}}):_vm._e()],1):_vm._e(),(_vm.activeTab === 'Badges')?_c('div',{staticClass:"mh-400"},[_c('div',{staticClass:"row no-gutters px-2 px-sm-4 mt-n8"},[(_vm.badges.length === 0 && !_vm.badges.loaded)?_c('Loading',{staticClass:"mt-8 w-100",attrs:{"fullscreen":false}}):_vm._e(),_vm._l((_vm.badges),function(badge,index){return _c('div',{key:index,staticClass:"badge-col col-4 d-flex justify-content-center"},[_c('Badge',{style:({ 'animation-delay': index / 20 + 's' }),attrs:{"type":badge.type,"icon":badge.icon,"id":badge.id,"size":"small"}})],1)}),(_vm.badges.loaded && _vm.badges.length === 0)?_c('Empty',{staticClass:"mt-10"}):_vm._e(),(_vm.badges.length > 0 && _vm.$route.query.event)?_c('router-link',{staticClass:"fade-up-ani btn mx-4 mt-8 btn-block btn-black",staticStyle:{"animation-delay":"1s"},attrs:{"tag":"button","to":'/' + _vm.$route.query.event + '/badges'}},[_vm._v("View all badges")]):_vm._e()],2)]):_vm._e(),(_vm.activeTab === 'Jobs')?_c('div',{staticClass:"mt-n3"},[_vm._l((_vm.positions.slice(
          0,
          !_vm.blockJobs || _vm.$user.uid ? _vm.positions.length : 4
        )),function(position,index){return _c('JobCard',{key:index,staticClass:"mx-5 fade-up-ani",style:({ 'animation-delay': index / 10 + 's' }),attrs:{"title":position.title,"desc":position.desc,"link":position.link,"date":position.date,"orgId":_vm.org.id}})}),(!_vm.positions.loaded)?_c('Skeleton'):_vm._e(),(_vm.positions.loaded && _vm.positions.length === 0)?_c('Empty',{staticClass:"mt-6"}):_vm._e(),(_vm.positions.length > 2 && _vm.blockJobs)?_c('Authblocker',{staticClass:"mt-4",attrs:{"CTA2":_vm.setAuthblockerVisibility,"blockContent":true}}):_vm._e()],2):_vm._e()]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"pp-text fade-up-ani input-tooltip text-center mt-5 mb-5 mx-5"},[_vm._v(" Data presented has been extracted from public sources and self-reporting; "),_c('a',{staticClass:"link",attrs:{"href":"https://gokind.app/assets/files/methodology.pdf"}},[_vm._v("see methodology")]),_vm._v(". Requests for corrections are lodged "),_c('a',{staticClass:"link",attrs:{"href":"mailto:hi@gokind.co"}},[_vm._v("here")]),_vm._v(". ")])}]

export { render, staticRenderFns }