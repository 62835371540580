import {
    GET,
    ADD,
    db,
    firebase
} from '@/firebase.js'

import jobber from '@/assets/js/jobber.js'
import JobCard from '@/components/JobCard/JobCard.vue'
import Authblocker from '@/components/Authblocker/Authblocker.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Avatar from '@/components/Avatar/Avatar.vue'
import Naver from '@/components/Naver/Naver.vue'
import Badge from '@/components/Badge/Badge.vue'
import Mat from '@/components/Mat/Mat.vue'
import Cell from '@/components/Cell/Cell.vue'
import Loading from '@/components/Loading/Loading.vue'
import Empty from '@/components/Empty/Empty.vue'
import Gad from '@/components/Gad/Gad.vue'
import Skeleton from '@/components/Skeleton/Skeleton.vue'

export default {
    name: 'Company',
    components: {
        JobCard,
        Authblocker,
        Tabs,
        Avatar,
        Naver,
        Badge,
        Mat,
        Cell,
        Loading,
        Empty,
        Gad,
        Skeleton
    },
    data: function () {
        return {
            positions: [],
            badges: [],
            org: {
                name: '-',

            },
            tabs: ['Overview', 'Badges', 'Jobs'],
            activeTab: 'Overview',
            overviewMat: {
                title: 'Looking for a thesis?',
                desc: 'Apply right away.',
                avatar: {
                    type: 'icon',
                    src: 'quill-pen-line'
                }
            },
            rankings: [],
            badgesFetched: 0,
            blockJobs: true
        }
    },
    methods: {
        setAuthblockerVisibility: function () {
            this.blockJobs = false
        },
        openInternWebsite: async function () {
            await this.addInternActivity()
            setTimeout(() => {
                window.location = this.org.thesis_link
            }, 500)
        },
        addInternActivity: function () {
            return new Promise(resolve => {
                let user = firebase.auth().currentUser;
                if (!user) return resolve(false)
                let col = db.collection('activity')
                let activityObj = {
                    date: new Date(),
                    uid: user.uid,
                    org: this.org.id,
                    type: 'internship'
                }
                ADD(col, activityObj).then(() => resolve(true))
            })
        },
        switchTab: function (tab) {
            this.activeTab = tab
            const query = {
                ...this.$route.query,
                t: tab
            };
            this.$router.push({
                query
            })
            this.triggerTabCB(tab)
        },
        triggerTabCB: function (tab) {
            if (tab === 'Jobs' && this.positions.length === 0) this.getJobs()
            if (tab === 'Badges' && this.badgesFetched === 0) this.getBadges()
        },
        addBadge: function (badgeId) {
            let doc = db.collection('badges').doc(badgeId)
            GET(doc).then(badge => {
                this.badges.push({
                    type: badge.data().type,
                    icon: badge.data().icon,
                    title: badge.data().title,
                    desc: badge.data().short_desc,
                    id: badge.id,
                })
            })
        },
        getBadges: function () {
            if (!this.org.badges) return this.badges.loaded = true
            this.badges = []
            this.org.badges.forEach(badge => {
                this.badgesFetched++
                if (this.badgesFetched > this.org.badges) return
                this.addBadge(badge.id)
            })
            setTimeout(() => {
                this.badges.loaded = true
            }, 1000)
        },
        expandPosition: function (position) {
            position.expand = !position.expand
            this.$forceUpdate()
        },
        setOrgVar: function (org) {
            this.org = {
                name: org.data().name,
                job_id: org.data().job_id,
                logo: org.data().logo,
                id: org.id,
                KPI: org.data().KPI,
                org_nums: org.data().org_nums,
                member: org.data().member,
                thesis_link: org.data().thesis_link,
                categories: org.data().categories,
                badges: org.data().badges,
                cj_id: org.data().cj_id
            }
            if (this.org.KPI) this.getKPI()
            if (this.badges.length === 0) this.getBadges()
            this.addActivityIfAuth(org.id)
        },
        rankingsSort(a, b) {
            return ('' + a.type).localeCompare(b.type);
        },
        getKPI: async function () {
            let KPI = this.org.KPI
            Object.keys(KPI).forEach(async e => {
                if (isNaN(KPI[e]['2020'].raw) && isNaN(KPI[e]['2020'].normalized)) return
                let ranking = await this.getRanking(e)
                this.rankings.push({
                    name: ranking.data().name,
                    desc: ranking.data().desc,
                    type: ranking.data().rid.split('_')[1],
                    id: ranking.id,
                    rid: e,
                    value: {
                        current: KPI[e][2020].raw,
                        trend: KPI[e].trend,
                        unit: ranking.data().unit,
                        incPos: ranking.data().inc_pos
                    }
                })
                this.rankings.sort(this.rankingsSort)
            });
        },
        getRanking: function (rid) {
            return new Promise(resolve => {
                let col = db.collection('rankings').where('rid', '==', rid).limit(1)
                GET(col).then(rankings => {
                    resolve(rankings.docs[0])
                })
            })
        },
        getOrg: function () {
            console.log('Fetching', this.$route.params.id)
            let col = db.collection('orgs').where('oid', '==', this.$route.params.id)
            GET(col).then(orgs => {
                if (orgs.empty) return console.log('Org does not exist')
                this.setOrgVar(orgs.docs[0])
                if (this.$route.query.t !== this.activeTab) this.setInitTab()
            })
        },
        getJobs: function () {
            jobber.get({
                orgNums: this.org.org_nums,
                location: 'Sverige',
                company: this.org.cj_id ? this.org.cj_id : this.org.name,
            }).then((jobs) => {
                console.log(jobs)
                if (jobs) this.positions = jobs
                this.positions.loaded = true
                this.$forceUpdate()
            })
        },
        setInitTab: function () {
            if (this.$route.query.t && this.org.id) {
                console.log(this.$route.params.id)
                this.activeTab = this.$route.query.t
                this.triggerTabCB(this.$route.query.t)
            }
        },
        activityExists: function (activity, uid, org) {
            return activity.some(actItem => {
                return actItem.uid === uid && actItem.org === org
            })
        },
        addActivityIfAuth: function (orgId) {
            let prevActivity = localStorage.getItem('activity') ? JSON.parse(localStorage.getItem('activity')) : []
            let user = firebase.auth().currentUser;
            if (!user || this.activityExists(prevActivity, user.uid, orgId)) return
            let col = db.collection('activity')
            let activityObj = {
                date: new Date(),
                uid: user.uid,
                org: orgId,
                type: 'visit'
            }
            ADD(col, activityObj).then(() => {
                prevActivity.push(activityObj)
                localStorage.setItem('activity', JSON.stringify(prevActivity))
            })
        },
    },
    watch: {
        '$route.params.id': function () {
            if (this.$route.params.id) this.getOrg()
        }
    },
    created: function () {
        this.setInitTab()
        this.getOrg()
    },
    mounted: function () {
        window.scrollTo(0, 0)
    }
}